<app-header></app-header>

<div class="sprucePro-banner-section">
    <div class="inner-section-banckground-image">
      <div class="pro-banner-image">
        <div class="container">
          <div class="banner-pro-logo">
            <img
              decoding="async"
              src="/assets/Spruce-Pro-Logo_Lockup_Large.png"
              alt="Spruce-Pro-Logo_Lockup_Large"
            />
            <div class="banner-pro-heading">
              <p>
                Grow your distributed solar portfolio’s productivity and
                profits. Leverage Spruce’s full-scope servicing and SREC
                management to meet your asset management needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="product-pro-section">
    <div class="container">
      <div class="row p-4 inner-list-section">
        <div class="col-md-12 col-lg-2">
          <div class="pro-icon">
            <img src="/assets/Spruce-Logo_Icon_Black.png" />
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="pro-list-heading">
            <h2>Productivity For Business</h2>
          </div>
        </div>
        <div class="col-md-12 col-lg-7">
          <div>
            <p>
              Leverage our team’s experience collaborating with utilities,
              commercial interests, and homeowners to achieve your company’s
              carbon-efficiency and clean energy goals in North America.
            </p>
          </div>
        </div>
      </div>
      <div class="row p-4 inner-list-section">
        <div class="col-md-12 col-lg-2">
          <div class="pro-icon">
            <img src="/assets/Panel-Icon-Small.png" />
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="pro-list-heading">
            <h2>Portfolio Servicing</h2>
          </div>
        </div>
        <div class="col-md-12 col-lg-7">
          <div>
            <p>
              Create efficiency and simplicity through Spruce’s suite of
              servicing tools specialized for photovoltaic portfolio owners.
              Our systemized approach integrates billing, collections,
              tax-equity partner &amp; lender management, accounting, and
              asset management services.
            </p>
          </div>
        </div>
      </div>
      <div class="row p-4 inner-list-section">
        <div class="col-md-12 col-lg-2">
          <div class="pro-icon">
            <img src="/assets/CO2L-1-df4c3ada.png" />
          </div>
        </div>
        <div class="col-md-12 col-lg-3">
          <div class="pro-list-heading">
            <h2>Environmental Commodities Markets</h2>
          </div>
        </div>
        <div class="col-md-12 col-lg-7">
          <div>
            <p>
              Create a new revenue stream by minting SRECs or build new
              sources of environmental commodities with our Environmental
              Commodities Markets (ECM) team.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="spruce-card-section">
    <div class="container inner-spruce-section">
      <div class="row">
        <div class="col-12">
          <p class="heading-inner-spruce">
            Even though Spruce Pro has a winning formula, that doesn’t mean we
            have a ‘one size fits all’ approach. We offer tailored solutions
            to meet the needs of specific use-case.
          </p>
        </div>
        <div class="col-12 main-card-pro">
          <div class="row">
            <div class="col-12 col-lg-4 py-3">
              <div class="card-pro">
                <div class="card-main">
                  <h4 class="card_header">
                    C&amp;I Solar Operators
                  </h4>
                  <div class="card_description">
                    <p>
                      Owners and developers of C&amp;I Solar benefit from
                      turn-key optimization which increases revenue and
                      decreases expenses of owned solar PV systems.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 py-3">
              <div class="card-pro">
                <div class="card-main">
                  <h4 class="card_header">
                   Enterprise Servicing
                  </h4>
                  <div class="card_description">
                    <p>
                      With scalable service platforms and an industry-leading
                      team of distributed energy professionals, Spruce can
                      customize solutions for your portfolio’s unique
                      customer, financial, and asset management needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 py-3">
              <div class="card-pro">
                <div class="card-main">
                  <h4 class="card_header">Community Solar</h4>
                  <div class="card_description">
                    <p>
                      We are residential solar’s top team in customer
                      satisfaction, and bring both our Texas hospitality and
                      public company controls to your community solar project.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="touch-button-section text-center">
    <div
  >
    <a
      class="button-touch"
      [routerLink]="['/contact-us']"
      >Get In Touch With Us
    </a>
  </div>
  </div>

<app-footer></app-footer>
