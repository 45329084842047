import { Component } from '@angular/core';
import { HeaderComponent } from "../header/header.component";
import { FooterComponent } from "../footer/footer.component";
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-pro',
  standalone: true,
  templateUrl: './pro.component.html',
  styleUrl: './pro.component.scss',
  imports: [HeaderComponent, FooterComponent, RouterLink]
})
export class ProComponent {

}
